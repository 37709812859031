<template>
  <exception-page type="401" :show-login-button="true" />
</template>

<script>
import ExceptionPage from '@/components/Exception'

export default {
  components: {
    ExceptionPage,
  },
}
</script>

<style scoped>
</style>
